<template>
  <DelayHydration>
    <FooterMain
      :navigation-menu="navigation"
      :contact-data="contactData"
      :social-menu="socialNavigation"
      :locale="translations[translationKey]"
      :is-main-page="isMainPage"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { useCurrentLocale } from '#imports'

import translations from './translations.json'
const translationKey = useCurrentLocale<typeof translations>()

const FooterMain = defineAsyncComponent(
  () => import('~ui/components/Base/FooterMain/FooterMain.vue')
)

defineProps({
  navigation: {
    type: Array,
    default: () => []
  },
  socialNavigation: {
    type: Array,
    default: () => []
  },
  contactData: {
    type: Array,
    default: () => []
  },
  isMainPage: {
    type: Boolean,
    default: true
  }
})
</script>
